import { V3IconChevronDown } from "../Icons";
import { SharedInputComponent, SharedInputProps } from "../SharedInputComponent";
import { SelChevPos, V3Sel } from "./V3Select.styles";

interface V3SelectProps extends SharedInputProps, React.InputHTMLAttributes<HTMLSelectElement> {
    options: { value: string | number, text: string | null, icon?: JSX.Element }[],
    disabledOption?: string
}

export const V3Select = ({ label, isRounded, caption, error, actionText, actionClickHandler, options, value, disabledOption, tooltipContent, tooltipMaxWidth, ...rest }: V3SelectProps) => {

    const hasError = !!error;

    let icon: JSX.Element | undefined;

    if (value) {
        const selOption = options.filter(opt => opt.value === value)[0];
        if (selOption && selOption.icon) icon = selOption.icon;
    }

    return <SharedInputComponent
        label={label}
        isRounded={isRounded}
        caption={caption}
        icon={icon}
        error={error}
        actionText={actionText}
        actionClickHandler={actionClickHandler}
        hasError={hasError}
        customErrorRightOffset={46}
        tooltipContent={tooltipContent}
        tooltipMaxWidth={tooltipMaxWidth}
    >
        <SelChevPos >
            <V3IconChevronDown />
        </SelChevPos>
        <V3Sel {...rest} $hasError={hasError} $hasIcon={!!icon} value={value}>
            {
                !!disabledOption && <option value="" defaultValue="">{disabledOption}</option>
            }
            {
                options.map(opt => <option value={opt.value} key={opt.value}>{opt.text}</option>)
            }
        </V3Sel>
    </SharedInputComponent>
}