import styled from "styled-components";
import { media } from "../../theme/theme";

export const ItemPreviewDateInputWrapper = styled.div`
    width: 150px;
`;

export const ItemPreviewBtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ItemPreviewSourceDateWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    ${media.desktop} {
        flex-direction: row;
    }

    > div {
        margin-bottom: 18px;

        ${media.desktop} {
            width: auto;
            margin-bottom: 0;
        }

        &:first-child {
            width: 100%;
            margin-right: 20px;
        }
    }
`;