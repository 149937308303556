import styled from "styled-components";
import { InputMixin, InputStyleProps } from "../SharedInputComponent";
import { V3Body } from "../Typography";

export const V3CCDisabledInput = styled(V3Body) <InputStyleProps>`
    ${InputMixin};
    height: 36px;
    overflow: hidden;
    color: transparent;
    text-shadow: 0 0 0 ${({ theme }) => theme.v3.colors.grey48};
    
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`;