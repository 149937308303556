import styled from "styled-components";
import { media, theme } from "../../theme/theme";

export const MediaEditingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    > div {
        &:first-child {
            margin-bottom: 20px;

            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                min-height: 350px;
                margin-bottom: 0;
            }
        }

        &:first-child,
        &:nth-child(2) {
            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                max-width: calc(50% - 30px);
                width: 100%;
            }
        }        
    }
`;

export const PreviewLabelWrapper = styled.div`
    max-width: 380px;
  
    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        margin: 0 auto;
    }
`;
