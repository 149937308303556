import { ContentType } from "authory-api-types/dist/enums";
import { SidebarLayoutShowMobile } from "../../layout/SideBarLayout";
import { truncate } from "../../utils/article-text-utils";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { V3InputLabel } from "../SharedInputComponent";
import { V3Body, V3HeadlineH4 } from "../Typography";
import { ItemMetadata } from "../V3ProfileListItem";
import { VerticalSpacing } from "../VerticalSpacing";
import { MPMediaCard, MPMediaImage, PPPDFNote, PreviewNoContentSet } from "./ItemPreview.styles";
import { MAX_DESCRIPTION_SIZE } from "../../types/max_description_size";

export enum ItemPreviewVariant {
    Primary = "Primary",
    Secondary = "Secondary",
}

export interface ItemPreviewProps {
    date?: string,
    sourceName?: string,
    originalUrl?: string,
    contentType?: ContentType,
    isEmpty: boolean,
    imageSrc?: string,
    title?: string,
    description?: string,
    showMetaContent?: boolean,
    variant?: ItemPreviewVariant
}

export const ItemPreview = ({ date, sourceName, originalUrl, contentType, isEmpty, imageSrc, title, description, showMetaContent, variant = ItemPreviewVariant.Primary }: ItemPreviewProps) => {

    const isPDF = contentType === ContentType.PdfFile;

    return <>
        {
            isEmpty ? <PreviewNoContentSet>
                <V3HeadlineH4>Enter title and description to see preview here.</V3HeadlineH4>
            </PreviewNoContentSet> : <MPMediaCard $variant={variant}>
                <div>
                    <SidebarLayoutShowMobile>
                        <VerticalSpacing bottom={8}>
                            <V3InputLabel><strong>Preview</strong></V3InputLabel>
                        </VerticalSpacing>
                    </SidebarLayoutShowMobile>
                    {
                        !!imageSrc && <VerticalSpacing bottom={24}>
                            <MPMediaImage
                                image={imageSrc}
                            />
                        </VerticalSpacing>
                    }
                    {
                        isPDF && !imageSrc && <VerticalSpacing bottom={24}>
                            <PPPDFNote>
                                <V3CaptionGrey35>Note: If you don't upload an image, Authory will automatically generate a thumbnail of the PDF.</V3CaptionGrey35>
                            </PPPDFNote>
                        </VerticalSpacing>
                    }
                    <VerticalSpacing bottom={8}>
                        <V3HeadlineH4>{title}</V3HeadlineH4>
                    </VerticalSpacing>
                    <V3Body>{truncate(description || "", MAX_DESCRIPTION_SIZE)}</V3Body>
                    {
                        showMetaContent && !!date && contentType && <VerticalSpacing top={12}>
                            <ItemMetadata
                                item={{
                                    originalUrl: originalUrl ? originalUrl : null,
                                    date,
                                    sourceName,
                                    type: contentType
                                }}
                                showRedirect={false}
                            />
                        </VerticalSpacing>
                    }
                </div>
            </MPMediaCard>
        }
    </>;
}