import { copyTextToClipboard } from "../../utils/copyToClipboard";
import { V3TertiaryButton } from "../Button";
import { V3IconCopy } from "../Icons";
import { VerticalSpacing } from "../VerticalSpacing";
import { V3CCDisabledInput } from "./V3CopyToClipboard.styles";

interface V3CopyToClipboardProps {
    url: string,
    trimProtocol?: boolean,
    copyText?: string
}

export const V3CopyToClipboard = ({ url, trimProtocol = true, copyText = "Copy link" }: V3CopyToClipboardProps) => {

    const onClickHandler = () => {
        copyTextToClipboard(url);
    }

    return <div onClick={onClickHandler}>
        <V3CCDisabledInput
            as="input"
            value={trimProtocol ? url.replace("https://", "") : url}
            readOnly
        />
        <VerticalSpacing top={6}>
            <V3TertiaryButton
                text={copyText}
                icon={<V3IconCopy />}
            />
        </VerticalSpacing>
    </div>
}