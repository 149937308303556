import { VerticalSpacing } from "../VerticalSpacing"

export enum V3ModalIconSegmentVariant {
    warning = "warning",
    error = "error",
}

interface ModalIconSegmentProps {
    variant: V3ModalIconSegmentVariant
}

export const ModalIconSegment = ({ variant }: ModalIconSegmentProps) => {
    return <VerticalSpacing bottom={12}>
        {
            variant === V3ModalIconSegmentVariant.warning && <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#FFF9E9" />
                <rect x="7" y="7" width="34" height="34" rx="17" fill="#FFF0C5" />
                <path d="M24 27V27.01M24 21V23V21Z" stroke="#FFBE00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.0002 30.9995H31.0002C31.3265 30.9972 31.6473 30.9151 31.9346 30.7604C32.2219 30.6057 32.4669 30.383 32.6484 30.1118C32.8299 29.8406 32.9422 29.5291 32.9756 29.2045C33.0091 28.8799 32.9626 28.552 32.8402 28.2495L25.7402 15.9995C25.5672 15.6869 25.3137 15.4264 25.0059 15.2449C24.6982 15.0635 24.3474 14.9678 23.9902 14.9678C23.6329 14.9678 23.2822 15.0635 22.9744 15.2449C22.6667 15.4264 22.4131 15.6869 22.2402 15.9995L15.1402 28.2495C15.0201 28.5451 14.9725 28.8651 15.0013 29.1828C15.0302 29.5006 15.1346 29.8068 15.3058 30.0759C15.4771 30.3451 15.7103 30.5694 15.9859 30.73C16.2615 30.8907 16.5716 30.9831 16.8902 30.9995" stroke="#FFBE00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
        {
            variant === V3ModalIconSegmentVariant.error && <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#FFF1F0" />
                <rect x="7" y="7" width="34" height="34" rx="17" fill="#FFDEDC" />
                <path d="M24 33a9 9 0 1 0 0-18 9 9 0 0 0 0 18ZM24 20v4M24 28h.01" stroke="#D60024" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
    </VerticalSpacing>
}