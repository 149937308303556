import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";
import { V3Loader } from "../Loader";
import { ModalIconSegment, V3ModalIconSegmentVariant } from "../ReusableStyledComponents/ModalIconSegment";
import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48";
import { V3Title } from "../Typography";
import { V3ModalBase } from "../V3ModalBase";
import { VerticalSpacing } from "../VerticalSpacing";
import { ConfirmationModalBtnPanel } from "./V3ConfirmationModal.styles";

interface V3ConfirmationModalProps {
    onSubmit: () => void,
    setModalOpen: (open: boolean) => void,
    modalOpen: boolean,
    title: string,
    name?: string,
    btnLabel: string,
    description?: string | JSX.Element,
    variant: V3ModalIconSegmentVariant,
    useIcon?: boolean,
    loading?: boolean,
}

export const V3ConfirmationModal = ({ modalOpen, variant, useIcon, title, setModalOpen, description, btnLabel, onSubmit, loading = false }: V3ConfirmationModalProps) => <V3ModalBase
    isOpen={modalOpen}
    onClose={() => {
        setModalOpen(false);
    }}
>
    {
        useIcon && <ModalIconSegment
            variant={variant}
        />
    }
    <VerticalSpacing bottom={12}>
        <V3Title>{title}</V3Title>
    </VerticalSpacing>
    <VerticalSpacing bottom={24}>
        {
            typeof description === "string" ? <V3BodyGrey48>{description}</V3BodyGrey48> : description
        }
    </VerticalSpacing>
    <ConfirmationModalBtnPanel>
        <div>
            <V3Button
                text={"Cancel"}
                autoWidth
                color={V3ButtonColor.secondary}
                onClick={() => {
                    setModalOpen(false);
                }}
                disabled={loading}
            />
        </div>
        <div>
            {
                loading ? <V3Loader background="transparent" height={42} /> : <V3Button
                    text={btnLabel}
                    autoWidth
                    color={variant === V3ModalIconSegmentVariant.error ? V3ButtonColor.error : V3ButtonColor.primary}
                    onClick={onSubmit}
                />
            }
        </div>
    </ConfirmationModalBtnPanel>
</V3ModalBase>