import styled, { css } from "styled-components";
import { media, theme } from "../../theme/theme";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { ItemPreviewVariant } from "./ItemPreview";

const imageDim = css`
    padding-bottom: 53.71%;
    border-radius: 8px;
`;

export const MPMediaCard = styled.div<{ $variant: ItemPreviewVariant }>`
    border-radius: 12px;
    max-width: 380px;
    margin: 0 auto 0 0;
    border: ${({ $variant }) => $variant === ItemPreviewVariant.Secondary ? `1px solid ${theme.v3.colors.grey88}` : undefined};

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        margin: 0 auto;
        padding: 12px;
        background: #FFFFFF;
    }
`;

export const MPMediaImage = styled.div<{ image?: string }>`
    background: ${({ theme, image }) => {
        if (image) return `url(${image}) center center / cover no-repeat`;
        return theme.v3.colors.grey70;
    }};
    width: 100%;
    ${imageDim}
`;

export const PreviewNoContentSet = styled.div`
    max-width:  200;
    text-align: center;
`;

export const PPPDFNote = styled.div`
    border: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : "black"};
    position: relative;
    ${imageDim}

    ${V3CaptionGrey35} {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        max-width: 300px;
        transform: translate(-50%, -50%);
    }
`;