import styled from "styled-components";
import { InputStyleProps, InputMixin } from "../SharedInputComponent";

export const V3Sel = styled.select<InputStyleProps>`
    ${InputMixin};
    appearance: none;
    padding-right: ${({ $hasError }) => $hasError ? "66px" : "38px"};
    color: ${({ theme }) => theme.v3.colors.grey48};
    font-size: 13px;
`;

export const SelChevPos = styled.div`
    position: absolute;
    top: 13px;
    right: 18px;
    pointer-events: none;
`;