import { Visibility } from "authory-api-types/dist/enums";
import { Collection } from "authory-api-types/dist/types";

export const getV3ProfileCollections = (collections?: Collection[]) => {
    const profileCollections = (collections || [])
        .sort((a, b) => a.order - b.order)
        .filter(col => col.visibility === Visibility.Public);

    const firstCollection = profileCollections.length ? profileCollections[0] : undefined;

    return { profileCollections, firstCollection };
}