export const decodeEntities = (function () {

    if (typeof window === 'undefined') return ((str: string) => str);

    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities(str: string) {
        let newStr = str;

        if (str && typeof str === 'string') {
            // strip script/html tags
            element.innerHTML = str;
            newStr = element.textContent || "";
            element.textContent = '';
        }

        return newStr;
    }

    return decodeHTMLEntities;
})();