import styled from "styled-components";

export enum V3BlueRoundPlusColor {
    primary = "primary",
    secondary = "secondary",
    tertiary = 'tertiary',
}

const SVG = styled.svg<{ $colorVariant: V3BlueRoundPlusColor }>`
    > path {
        &:nth-child(1) {
            fill: ${({ theme, $colorVariant }) => {
        switch ($colorVariant) {
            case V3BlueRoundPlusColor.primary:
                return theme.v3.colors.blue;
            case V3BlueRoundPlusColor.secondary:
                return theme.v3.colors.grey70;
            case V3BlueRoundPlusColor.tertiary:
                return undefined;
        }
    }};  
        }
    }

    &:hover {
        > path {
            fill: ${({ $colorVariant, theme }) => {
        if ($colorVariant === V3BlueRoundPlusColor.tertiary) return undefined;
        return theme.v3.colors.blue
    }};
            opacity: 1;
        }
    }
`;

interface V3BlueRoundPlusProps extends React.SVGProps<SVGSVGElement> {
    colorVariant?: V3BlueRoundPlusColor,
}

export const V3BlueRoundPlus = ({ colorVariant = V3BlueRoundPlusColor.primary, ...rest }: V3BlueRoundPlusProps) => <SVG width="16" height="16" {...rest} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" $colorVariant={colorVariant}>
    <path d="M.75 8a7.25 7.25 0 1 1 14.5 0A7.25 7.25 0 0 1 .75 8Z" />
    <path d="M8 4.75v6.5M11.25 8h-6.5" style={{ stroke: colorVariant === V3BlueRoundPlusColor.tertiary ? undefined : "white" }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</SVG>