import React from "react";
import { VerticalSpacing } from "../VerticalSpacing";
import { MediaEditingWrapper, PreviewLabelWrapper } from "./V3PanelWithPreview.styles";
import { SidebarLayoutShowDesktop, SidebarLayoutShowMobile } from "../../layout/SideBarLayout";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35";
import { ItemPreview, ItemPreviewProps } from "../ItemPreview";
import { ContentItemPreviewLearnMore } from "../ReusableStyledComponents/ContentItemPreviewLearnMore";

interface V3PanelWithPreviewProps extends Omit<ItemPreviewProps, "isEmpty"> {
    showMediaPreview?: boolean,
    mainPanel: JSX.Element,
    btnPanel?: JSX.Element,
    previewEmpty?: boolean,
    hidePreviewLabel?: boolean,
    isBulkImport?: boolean,
}

export const V3PanelWithPreview = ({
    title,
    description,
    imageSrc,
    showMediaPreview,
    mainPanel,
    showMetaContent = false,
    date,
    sourceName,
    originalUrl,
    contentType,
    btnPanel,
    hidePreviewLabel = false,
    isBulkImport
}: V3PanelWithPreviewProps) => {

    const isEmpty = !title?.length && !description?.length && !imageSrc?.length;
    const WrapperToUse = showMediaPreview ? MediaEditingWrapper : React.Fragment;
    const showPreviewLabel = showMetaContent && !hidePreviewLabel && !isEmpty;

    return <WrapperToUse style={{ position: "relative" }}>
        <div>
            {mainPanel}
            <SidebarLayoutShowDesktop>
                {btnPanel}
            </SidebarLayoutShowDesktop>
        </div>
        {
            showMediaPreview && <div style={{ marginTop: isBulkImport ? -35 : 0 }}>
                <ItemPreview
                    date={date}
                    sourceName={sourceName}
                    originalUrl={originalUrl}
                    contentType={contentType}
                    isEmpty={isEmpty}
                    imageSrc={imageSrc}
                    title={title}
                    description={description}
                    showMetaContent={showMetaContent}
                />
                {
                    showPreviewLabel && <VerticalSpacing top={28}>
                        <PreviewLabelWrapper>
                            <V3BodyGrey35>Preview of what the content will look like on your portfolio, in collections and when shared on social media.  <ContentItemPreviewLearnMore /></V3BodyGrey35>
                        </PreviewLabelWrapper>
                    </VerticalSpacing>
                }
            </div>
        }
        <SidebarLayoutShowMobile>
            {btnPanel}
        </SidebarLayoutShowMobile>
    </WrapperToUse>
}
